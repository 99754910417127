import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
  useContext,
  useMemo,
  SyntheticEvent,
  useRef,
  useCallback,
} from "react";
import "../../../../Css/style.css";
import "../../../../Css/table.css";
import "./Banners.css";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { Pagination, Stack } from "@mui/material";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Loader from "../../../../Components/Loader/Loader";
import { API_Endpoint } from "../../../../Configuration/Settings";
import { Responsibilities } from "../../../../Configuration/Enums";
import { User_Context } from "../../../../Contexts/User";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import Reusable_Input from "../../../../Components/InputField/InputField";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";

interface Payment {
  _id: string;
  payment_id?: string;
  amount?: string;
  orderkeyid?: string;
  pg: string;
  date?: Date;
  client?: string;
  status?: string;

  reconciliationStatus?: string;
}

const initialDescriptionData = {
  titleError: "",
  subtitleError: "",
  titleHelperText: "",
  subTitleHelperText: "",
};

function Banners() {
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [linkError, setLinkError] = useState<boolean>(false);
  const [linkHelperText, setLinkHelperText] = useState<string>("");
  const [selectedFileHelperText, setSelectedFileLinkHelperText] =
    useState<string>("");
  const [mobLink, setMobLink] = useState<string>("");
  const [mobError, setMobError] = useState<boolean>(false);
  const [mobHelperText, setMobHelperText] = useState<string>("");
  const [gameName, setGameName] = useState<string>("");
  const [gameNameError, setGameNameError] = useState<boolean>(false);
  const [gameNameHelperText, setGameNameHelperText] = useState<string>("");
  const [selectedFileError, setSelectedFileError] = useState<boolean>(false);
  const [typeHelperText, setTypeHelperText] = useState<string>("");
  const [typeError, setTypeError] = useState<boolean>(false);
  const [categoryError, setCategoryError] = useState<boolean>(false);
  const [categoryHelperText, setCategoryHelperText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  //const [images, setImages] = useState<Array<Images>>([]);
  const [payments, setPaymentData] = useState<Array<Payment>>([]);
  const [imageId, setImageId] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const { User } = useContext(User_Context);
  const [File_Name, Set_File_Name] = useState<string>("");
  const [Image, Set_Image] = useState<string>("");
  const [file, setFile] = useState<any>(null);
  const [startDate, setStartDate] = useState<string>("");
  const [startDateError, setStartDateError] = useState<boolean>(false);

  const [endDate, setEndDate] = useState<string>("");
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [descriptionError, setDescriptionError] = useState<{
    titleError: string;
    subtitleError: string;
  }>(initialDescriptionData);
  const [description, setDescription] = useState<{
    title: string;
    subTitle: string;
  }>({ title: "", subTitle: "" });

  const [mobileRoute, setMobileRoute] = useState<string>("");
  const [mobileParams, setMobileParams] = useState<string>("");

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      // const reader = new FileReader();
      // const File_Object = event.target.files[0];
      // reader.readAsDataURL(File_Object);
      // reader.onload = () => {
      //   Set_File_Name(File_Object.name);
      //   Set_Image(reader.result?.toString() as string);
      // };
    }
  };

  const handleUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setUrl(event.target.value);
    setLinkError(false);
  };

  const handleMobChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMobLink(event.target.value);
    setMobError(false);
  };

  const handleGameNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setGameName(event.target.value);
    setGameNameError(false);
  };

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    setSelectedOption(event.target.value);
    setTypeError(false);
  };

  const handleDropdownChangeMobilePage = (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    setMobileRoute(event.target.value);
    setTypeError(false);
  };

  const handleDropdownChangeMobileParams = (
    event: SelectChangeEvent<string>
  ) => {
    event.preventDefault();
    setMobileParams(event.target.value);
    setTypeError(false);
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    setSelectedCategory(event.target.value);
    setCategoryError(false);
  };

  // let imageId: string = '111111111111'
  const [openDialogForImage, setOpenDialogForImage] = useState<boolean>(false);

  const getAllBanners = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let filter = {}
    if (startDate && endDate) {
      filter = { startDate: startDate, endDate: endDate }
    }
    let data = {
      pageSize: Number(itemsPerPage),
      pageNumber: currentPage,
      filter: filter,
      startDate,
      endDate
    };
    axios
      .post(
        `${API_Endpoint}/backoffice/reports/s2pay-history`,
        { token: encryptData(data) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: any) => {
        response.data.data = decryptData(response.data.data);
        setPaymentData(response.data.data);
        setTotalPages(response.data.totalPages)
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error.message);
      });
  };
  const getCalSummary = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let filter = {}
    if (startDate) {
      filter = { startDate: startDate, endDate: endDate }
    }
    let data = {
      pageSize: Number(itemsPerPage),
      pageNumber: currentPage,
      startDate: startDate,
      endDate: endDate
    };
    axios
      .post(
        `${API_Endpoint}/backoffice/reports/s2pay-dashbord`,
        { token: encryptData(data) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: any) => {
        response.data.data = decryptData(response.data.data);
        setPaymentData(response.data.data);
        setTotalPages(response.data.totalPages)
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error.message);
      });
  };
  const importOurData = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    let filter = {}
    if (startDate) {
      filter = { startDate: startDate, endDate: endDate }
    }
    let data = {
      pageSize: Number(itemsPerPage),
      pageNumber: currentPage,
      startDate: startDate,
      endDate: endDate
    };
    axios
      .post(
        `${API_Endpoint}/backoffice/import/sb2pay`,
        { token: encryptData(data) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: any) => {
        response.data.data = decryptData(response.data.data);
        getAllBanners();
        //setPaymentData(response.data.data);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log(error.message);
      });
  };
  useEffect(() => {
    getAllBanners();
  }, [currentPage, itemsPerPage]);
  const handlePerPage = (newValue: any) => {
    setItemsPerPage(newValue)
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const openPopup = (id: string) => {
    setDeletePopup(true);
    setImageId(id);
  };
  const deleteImage = () => {
    setLoading(true);
    setDeletePopup(false);
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(
          `${API_Endpoint}/bannerGames/delete`,
          { token: encryptData({ _id: imageId }) },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          // const updatedImageStatus = images.filter(
          //   (item) => item._id !== imageId
          // );
          // setImages(updatedImageStatus);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error ", error);
        });
    }
  };

  // const handleAddSwitchChange = (e) => {
  //   setSwitchAddState(e.target.checked)
  // }
  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const checked = event.target.checked;
    const id = event.target.id;
    const apiUrl = `${API_Endpoint}/bannerGames`;
    const postData = {
      _id: id,
      status: checked,
    };
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(
          `${apiUrl}/update`,
          { token: encryptData(postData) },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // const updatedImageStatus = images.map((item) => {
          //   if (item._id == id) {
          //     return {
          //       ...item,
          //       status: checked,
          //     };
          //   } else return item;
          // });
          // setImages(updatedImageStatus);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!file) {
      setSelectedFileError(true);
      setSelectedFileLinkHelperText("Choose file");
    }
    // else if (!url) {
    //   setLinkError(true);
    //   setLinkHelperText("Enter desktop link");
    // }
    // else if (!mobLink) {
    //   setMobError(true);
    //   setMobHelperText("Enter mobile link");
    // }
    // else if (!gameName) {
    //   setGameNameError(true);
    //   setGameNameHelperText("Enter game name");
    // } else if (!selectedOption) {
    //   setTypeError(true);
    //   setTypeHelperText("Select type");
    // }
    else if (!selectedCategory) {
      setCategoryError(true);
      setCategoryHelperText("Select category");
    }
    // else if (selectedOption === "bonusScreenBanners" && !description.title) {
    //   setDescriptionError({
    //     ...descriptionError,
    //     titleError: "Enter Banner Title",
    //   });
    // } else if (selectedOption === "bonusScreenBanners" && !description.subTitle) {
    //   setDescriptionError({
    //     ...descriptionError,
    //     subtitleError: "Enter Banner Subtitle",
    //   });
    // }
    else {
      setLoading(true);
      let payload: { [key: string]: string | boolean | number | { [key: string]: string } } = {
        iframeUrlMob: mobileRoute,
        iframeUrl: url,
        type: selectedOption,
        File_Name: File_Name,
        Image: Image,
        gameName: gameName,
        category: selectedCategory,
        deepLink: true,
        mobileRouter: mobileRoute,
        mobileOptions: mobileParams,
      };
      const { title, subTitle } = description;
      if (selectedOption === 'bonusScreenBanners' && title && subTitle) {
        payload['decryption'] = description
      }
      const formData: any = new FormData();
      formData.append('file', file);
      formData.append('startDate', startDate);


      axios
        .post(
          `${API_Endpoint}/backoffice/import/sb2paycsv`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${User.token}`,
              'Content-Type': 'multipart/form-data',

            },
          }
        )
        .then((response) => {
          response.data.data = decryptData(response.data.data);
          // setImages(response.data.data.payload);
          getAllBanners();
          setOpenDialogForImage(false);
          setLoading(false);
        })
        .catch((err: any) => {
          setOpenDialogForImage(false);
          setLoading(false);
          console.log(`${err.message}`);
        });
    }
  };
  const label = useMemo(() => {
    return { inputProps: { "aria-label": "Switch demo" } };
  }, []);

  const handleBannerTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value || "";
      if (value.length) {
        setDescriptionError({ ...descriptionError, titleError: "" });
      }
      setDescription({ ...description, title: event.target.value });
    },
    [description, descriptionError]
  );

  const handleBannerSubTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value || "";
      if (value.length) {
        setDescriptionError({ ...descriptionError, subtitleError: "" });
      }
      setDescription({ ...description, subTitle: event.target.value });
    },
    [description, descriptionError]
  );
  const handleApply = () => {
    if (!startDate) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
      setCurrentPage(1);
      getAllBanners();
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <Sidenav />
          <main className="main-content position-relative">
            <div style={{ background: "#f8f9fa" }}>
              <Breadcrumbs
                tab={"S2pay List"}
                button={
                  User.data.Responsibilities.includes(
                    Responsibilities.Add_Banner
                  ) && (
                    <Button
                      onClick={() => setOpenDialogForImage(true)}
                      className="btn-payment"
                      variant="contained"
                    >
                      Add
                    </Button>
                  )
                }
              />
              {/* dialog open for insert image */}
              <div>
                <Dialog
                  open={openDialogForImage}
                  disableEnforceFocus
                  onClose={() => setOpenDialogForImage(false)}
                >
                  {/* <DialogTitle>Payment Form</DialogTitle> */}
                  <DialogContent>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div>
                        <TextField
                          onChange={handleFileChange}
                          error={selectedFileError}
                          helperText={selectedFileHelperText}
                          // value={selectedFile}
                          id="outlined-basic"
                          type="file"
                          size="small"
                          InputLabelProps={{
                            style: {
                              fontFamily: "Roboto",
                              fontSize: "14px",
                            },
                          }}
                        />
                      </div>

                      <div className="d-flex justify-content-between gp">
                        <FormControl
                          className="form-banner mt-2"
                          sx={{ m: 1, width: 200, marginLeft: 5 }}
                          size="small"
                        >
                          <InputLabel
                            className="label-banner"
                            id="demo-select-small-label"
                          >
                            Payment Gateway
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedCategory}
                            label="Select"
                            fullWidth
                            onChange={handleCategoryChange}
                            error={categoryError}
                          >
                            <MenuItem value="s2pay">s2pay</MenuItem>

                          </Select>
                        </FormControl>

                        {/* <FormGroup className="mt-2">
                          <FormControlLabel
                          control={
                            <Switch
                            checked={switchAddState}
                            onChange={(e) => handleAddSwitchChange(e)}
                            color="primary"
                            />
                          }
                          label={switchAddState ? "Active" : "Inactive"}
                          />
                        </FormGroup> */}
                      </div>
                      {selectedOption === "bonusScreenBanners" ? (
                        <div>
                          <div className="mt-2">
                            <Reusable_Input
                              type={"text"}
                              label={"Banner Title"}
                              fullWidth={true}
                              value={description?.title ?? ""}
                              error={!!descriptionError?.titleError?.length}
                              helperText={descriptionError?.titleError}
                              onChange={handleBannerTitleChange}
                            />
                          </div>
                          <div className="mt-2">
                            <Reusable_Input
                              type={"text"}
                              label={"Banner Subtitle"}
                              fullWidth={true}
                              value={description?.subTitle ?? ""}
                              error={!!descriptionError?.subtitleError}
                              helperText={descriptionError?.subtitleError}
                              onChange={handleBannerSubTitleChange}
                            />
                          </div>
                        </div>
                      ) : null}

                      <DialogActions>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          onClick={() => setOpenDialogForImage(false)}
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn-popup"
                          variant="outlined"
                          type="submit"
                          color="primary"
                        // onClick={deleteImage}
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <div>
                <Dialog
                  open={deletePopup}
                  onClose={() => setDeletePopup(false)}
                >
                  <DialogTitle>Are You Sure ?</DialogTitle>
                  <DialogContent>
                    <DialogActions className="mt-n3">
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        onClick={() => setDeletePopup(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-popup"
                        variant="outlined"
                        type="submit"
                        color="primary"
                        onClick={() => deleteImage()}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </Dialog>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="row tp-form">
                    <div className="d-flex align-items-center mb-1">
                      <div className="container-fluid">
                        <div className="row tp-form">
                          <div className="col-6 col-xl-2 col-sm-3 pdrt">
                            <label className="lbl">From Date</label>
                            
                            <input
                              type="date"
                             
                              className={`form-control ${startDateError ? "is-invalid" : ""}`}

                              placeholder="From Date"
                              value={startDate}
                              onChange={(e) => {setStartDate(e.target.value);setStartDateError(false)}}
                            />
                          </div>
                          {/* <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl">To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        placeholder="To Date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div> */}
                          <div className="col-6 col-xl-2 col-sm-3 pdrt">
                            <label className="lbl"></label>
                            <a className="sechBtn mt-1" onClick={handleApply}>
                              Apply
                            </a>
                          </div>
                          <div className="col-6 col-xl-2 col-sm-3 pdrt">
                            <label className="lbl"></label>
                            <a className="sechBtn mt-1" onClick={importOurData}>
                              Import Our Data
                            </a>
                          </div>
                          {/* <div className="col-6 col-xl-2 col-sm-3 pdrt">
                                    <label className="lbl"></label>
                                    <a onClick={clearDate} className="sechBtn mt-1">
                                        Clear Dates
                                    </a>
                                </div> */}
                        </div>
                      </div>
                      {/* <div className="col-6 col-xl-1 col-sm-4 search-4">
                        <ExcelExport
                          inputData={plData}
                          headings={headings}
                          fileName={"Profit & Loss"}
                        />
                      </div>
                      <div>
                        <span className="ms-1">Download Data</span>
                      </div>*/}


                    </div>

                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table table-view">
                        <thead>
                          <tr>
                            <th></th>
                            <th>pg </th>
                            <th>payment id</th>
                            <th>userid</th>
                            <th>client</th>
                            <th>ourAmt</th>
                            <th>pgAmt</th>
                            <th>diff</th>
                            <th>ourStatus</th>
                            <th>pgStatus</th>
                            <th>Date</th>
                            <th>Reconsile Status</th>

                          </tr>
                        </thead>
                        <tbody>
                          {payments.map((item: any, index: number) => {
                            return (
                              <tr key={item._id}>
                                <td> {index + 1 + (currentPage && itemsPerPage ? (currentPage - 1) * itemsPerPage : 0)}</td>
                                <td>{item.pg}</td>
                                <td>{item.payment_id}</td>
                                <td>{item.user_id}</td>
                                <td>{item.client}</td>
                                <td>{item.ourAmount}</td>
                                <td>{item.vendorAmount}</td>
                                <td>{item.difference}</td>

                                <td>{item.ourStatus}</td>
                                <td>{item.vendorStatus}</td>

                                <td>{item.date}</td>
                                <td>{item.reconciliationStatus}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <ul className="pagination d-flex justify-content-center">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={(event, newPage) => setCurrentPage(newPage)}
                        />
                      </Stack>
                    </ul>
                  </div>
                </div>
                <footer className="footer">
                  <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                      <div className="col-lg-6 mb-lg-0  ">
                        <div className="copyright text-center text-sm text-muted text-lg-start">
                          © fairbets.co
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              About Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link text-muted" target="_blank">
                              Blog
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link pe-0 text-muted"
                              target="_blank"
                            >
                              License
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
}

export default Banners;
