import React, { useState, useEffect, useContext } from "react";
import "../../../../Css/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Sidenav from "../../../../Components/SideNavigation/SideNavigation";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_Endpoint } from "../../../../Configuration/Settings";
import Loader from "../../../../Components/Loader/Loader";
import { decryptData } from "../../../../utils/decryptData";
import { encryptData } from "../../../../utils/encryptData";
import { User_Context } from "../../../../Contexts/User";
import Breadcrumbs from "../../../../Components/Breadcrumbs/Breadcrumbs";
import StatCard from "../../../../Components/Statistics/StatCard";
import DashboardCard from "../../../../Components/Statistics/DashboardCard";
import DashboardCardGraph, {BarChart_v, PieChart_v} from "../../../../Components/Statistics/DashboardCardGraph";
 

import { dateTime } from "../../../../utils/utility";
import { Link, useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";



interface User {
  _id: string;
  subAdminId: string;
  name: string;
  kyc: false;
  mobile: string;
  email: string;
  password: string;
  state: string;
  city: string;
  balance: number;
  deviceType: string;
  createdOn: string;
  updatedOn: string;
  __v: number;
  totalUserRegisterToday: number;
  totalUserCount: number;
  totalBalance: number;
  liabilities: number;
  totalCountCasino: number;
}

interface CasinoRes {
  totalCountCasino: number;
  totalBetAmountCasino: number;
  casino_profit: number;
  casinoGGR: number;
  qtechGGR: number
  qtechTotalBetAmount: number
  qtechTotalBetCount: number
  qtechTotalBetWinAmount: number
}

interface Sm {
  totalCountSm: number;
  smProfit: number;
  smTotalBetAmount: number;
  smGGr: number;
  sattaMatkaGGR: number
  sattaMatkaTotalBetAmount: number
  sattaMatkaTotalBetCount: number
  sattaMatkaTotalBetPendingAmount: number
  sattaMatkaTotalBetWinAmount: number
}
interface Falcon {
  falconGGR: number
  falconTotalBetAmount: number
  falconTotalBetCount: number
  falconTotalBetPendingAmount: number
  falconTotalBetWinAmount: number
}

interface Exchange {
  totalJtCount: number;
  totalJtBetAmount: number;
  jtProfit: number;
  jtGGR: number;
  jetfairTotalBetAmount: number;
  jetfairTotalBetCount: number;
  jetfairTotalBetPendingAmount: number;
  jetfairGgr: number;
}
interface Evolution {
  totalBet: number;
  totalWin: number;
  totalGames: number;
  GGR: number;
}

interface SubAdmin {
  reportDate: string;
  v_totalDeposit?: number;
  v_totalCount?: number;

  pg?: string;

  ourTotalDepost: number;
  ourTotalCount?: number;

  pgTotalData?: string;
  ourTotalData?: string;
   coinData: {
    totalscannerDepositCredit: number,
    totalscannerDepositDebit: number,
    totalmanualCredit: number,
  },
  depositUserDetail: {
    oldUserDepositSum: number,
    newUserDepositSum: number,
  },
  WithdrawalData: {
    todaysTotalApprovedAmount: number,
    previousTotalApprovedAmount: number,
  },
  depositData: {
    depositApprovedTotal: number
  }
}
function Home() {
  const [registeredUser, setRegisteredUser] = useState<number>(0);
  const [regUserWeb, setRegUserWeb] = useState<number>(0);
  const [regUserApp, setRegUserApp] = useState<number>(0);
  const [regUserWebToday, setRegUserWebToday] = useState<number>(0);
  const [regUserAppToday, setRegUserAppToday] = useState<number>(0);
  const [nonPerformingUser, setNonPerformingUser] = useState<number>(0);
  const [bonusBalanceUser, setBonusBalanceUser] = useState<number>(0);
  const [activeUser, setActiveUser] = useState<number>(0);
  const [activeUserApp, setActiveUserApp] = useState<number>(0);
  const [usersCreatedToday, setUsersCreatedToday] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [liability, setLiability] = useState<number>(0);
  const [globalUser, setGlobalUser] = useState<number>(0);
  const [casinoBets, setCasinoBets] = useState<CasinoRes>();
  const [exchange, setExchange] = useState<Exchange>();
  const [evolution, setEvolution] = useState<Evolution>();
  const [smBets, setSmBets] = useState<Sm>();
  const [falconBets, setFalconBets] = useState<Falcon>();
  const [loading, setLoading] = useState<boolean>(false);
  const { User } = useContext(User_Context)
  const [totalAmount, setTotalAmount] = useState<any>();

  const [startDate, setStartDate] = useState<string>(dateTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0]));
  const [endDate, setEndDate] = useState<string>(dateTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0]));
  const navigate = useNavigate()
  const [AllData, setAllData] = useState<boolean>(false);
  const [data, setData] = useState<any>({ startDate, endDate })
  const [itemsPerPage, setItemsPerPage] = useState<number>(() => {
    const savedItemsPerPage = localStorage.getItem('walletHistoryItemsPerPage');
    return savedItemsPerPage ? parseInt(savedItemsPerPage, 10) : 10;
  });
  const [totalActiveCustomers, setTotalActiveCustomers] = useState<number>(0)
  const [categoryError, setCategoryError] = useState<boolean>(false);
  const [categoryHelperText, setCategoryHelperText] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");


  const fundReqData = () => {
    const token = localStorage.getItem("token");
    return axios.post(`${API_Endpoint}/SubAdmin/fund-request`, { token: encryptData(data) }, {
      headers: {
        Authorization: `Bearer ${token}`,
        maxBodyLength: Infinity,
      },
    });
  };

  const dashboardData = () => {
    const token = localStorage.getItem("token");
    return axios.post(`${API_Endpoint}/User/dashboard`, { token: encryptData(data) }, {
      headers: {
        Authorization: `Bearer ${token}`,
        maxBodyLength: Infinity,
      },
    });
  };
  const centeralDashboardData = () => {
    const token = localStorage.getItem("token");
    return axios.post(`${API_Endpoint}/analytics/platform/all/main-dashbord`, { token: encryptData(data) }, {
      headers: {
        Authorization: `Bearer ${token}`,
        maxBodyLength: Infinity,
      },
    });
  };

  const getActiveCustomers = () => {
    const token = localStorage.getItem("token");
    let filter: { email?: string, mobile?: string, name?: string, city?: string, state?: string, accountNumber?: string, aadhaarNumber?: string, deviceType?: string } = {};

    let payload = {
      pageNo: 1,
      itemsPerPage: itemsPerPage,
      filter: filter
    };
    console.log(payload)
    return axios.post(`${API_Endpoint}/User/get-active-customers`, { token: encryptData(payload) }, {
      headers: {
        Authorization: `Bearer ${token}`,
        maxBodyLength: Infinity,
      },
    })
  }



  const handleApiCalls = () => {
    setLoading(true);//,fundReqData(), getActiveCustomers(),fundReqResponse, activeCustomerResponse
    axios.all([centeralDashboardData()])
      .then(axios.spread((dashboardResponse) => {
        const fundData = decryptData(dashboardResponse.data.data);

        setTotalAmount( fundData);
         // const dashboardData = decryptData(dashboardResponse.data.data);
        // const payload = dashboardData.payload;
        // const activeCustomers = decryptData(activeCustomerResponse.data.data);
        // setTotalActiveCustomers(activeCustomers.payload.count)
        // setRegisteredUser(payload.totalRegisterUsers);
        // setRegUserWeb(payload.totalRegisterUsersOfWeb);
        // setRegUserApp(payload.totalRegisterUsersOfApp);
        // setRegUserAppToday(payload.totalTodayRegisterUsersOfApp);
        // setRegUserWebToday(payload.totalTodayRegisterUsersOfWeb);
        // setNonPerformingUser(payload.totalNonPerformingUsers);
        // setBonusBalanceUser(payload.totalBonusBalanceOfUsers);
        // setActiveUser(payload.totalActiveUsers);
        // setActiveUserApp(payload.totalActiveUsersApp);
        // setUsersCreatedToday(payload.totalTodayRegisterUsers);
        // setBalance(payload.totalBalanceOfUsers);
        // setLiability(
        //   payload.falconTotalBetPendingAmount +
        //   payload.jetfairTotalBetPendingAmount +
        //   payload.sattaMatkaTotalBetPendingAmount
        // );
        // setCasinoBets(payload);
        // setExchange(payload);
        // setSmBets(payload);
        // setFalconBets(payload);
        setLoading(false);
      }))
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleApiCalls();
  }, [data]);

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    setSelectedCategory(event.target.value);
    setCategoryError(false);
  };
  const usersGlobalList = () => {
    let data = {
      startDate: startDate,
      endDate: endDate,
    }
    navigate("/users-global-list", { state: data })
  }

  // const getGlobalCount = () => {
  //   setLoading(true)
  //   const token = localStorage.getItem("token");
  //   let data = {}
  //   if (startDate && endDate) {
  //     data = {
  //       startDate: dateTime(startDate),
  //       endDate: dateTime(endDate)
  //     };
  //   } else {
  //     const currentDate = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().split('T')[0];
  //     data = {
  //       startDate: dateTime(currentDate),
  //       endDate: dateTime(currentDate)
  //     };
  //   }
  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: `${API_Endpoint}/User/get-user-globals-count`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //     data: { token: encryptData(data) }
  //   };
  //   axios
  //     .request(config)
  //     .then((response) => {
  //       response.data.data = decryptData(response.data.data)
  //       setGlobalUser(response.data.data.payload)
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }


  const handleAllData = (e: any) => {
    let text = e?.target?.id
    if (text == "alldata") {
      setData({})
      setStartDate("")
      setEndDate("")
    } else {
      setData({
        startDate: dateTime(startDate),
        endDate: dateTime(endDate),
      })
    }
  };

  const casinoBetsData = [
    { label: "Total Bet Amount", value: casinoBets?.qtechTotalBetAmount },
    { label: "Total Bet Count", value: casinoBets?.qtechTotalBetCount },
    { label: "Total Win Amount", value: casinoBets?.qtechTotalBetWinAmount },
    { label: "GGR", value: casinoBets?.qtechGGR },
  ];
  const exchangeBetsData = [
    { label: "Total Bet Amount", value: exchange?.jetfairTotalBetAmount },
    { label: "Total Bet Count", value: exchange?.jetfairTotalBetCount },
    { label: "Total Bet Pending Amount", value: exchange?.jetfairTotalBetPendingAmount },
    { label: "GGR", value: exchange?.jetfairGgr },
  ];
  const smBetsData = [
    { label: "Total Bet Amount", value: smBets?.sattaMatkaTotalBetAmount },
    { label: "Total Bet Count", value: smBets?.sattaMatkaTotalBetCount },
    { label: "Total Bet Pending Amount", value: smBets?.sattaMatkaTotalBetPendingAmount },
    { label: "Total Win Amount", value: smBets?.sattaMatkaTotalBetWinAmount },
    { label: "GGR", value: smBets?.sattaMatkaGGR },
  ];
  const falconBetsData = [
    { label: "Total Bet Amount", value: falconBets?.falconTotalBetAmount },
    { label: "Total Bet Count", value: falconBets?.falconTotalBetCount },
    { label: "Total Bet Pending Amount", value: falconBets?.falconTotalBetPendingAmount },
    { label: "Total Win Amount", value: falconBets?.falconTotalBetWinAmount },
    { label: "GGR", value: falconBets?.falconGGR },
  ];
  const evolutionBetsData = [
    { label: "Total Bet Amount", value: evolution?.totalBet },
    { label: "Total Bet Count", value: evolution?.totalGames },
    // { label: "Total Bet Pending Amount", value: evolution?.jetfairTotalBetPendingAmount },
    { label: "GGR", value: evolution?.GGR },
  ];
const MergedTable  = ({ totalAmount }:any) => {
if(!totalAmount){
  return <div></div>;

}
  
  const mergedData = totalAmount.ourTotalData.map((itemA:any) => {
    const itemB = totalAmount.pgTotalData.find((item:any) => item._id === itemA._id);
    return {
      id: itemA._id,
      totalDeposit: itemA.totalDeposit,
      totalCount: itemA.totalCount,
      v_totalDeposit: itemB?.v_totalDeposit || "N/A",
      v_totalCount: itemB?.v_totalCount || 0,
    };
  });

  return (
    <table className="table table-view">
      <thead>
        <tr>
          <th>ID</th>
          <th>ourTotalData</th>
          <th>totalCount</th>
          <th>S2pay_Deposit</th>
          <th>S2pay_totalCount</th>
        </tr>
      </thead>
      <tbody>
        {mergedData?.map((row:any) => (
          <tr key={row.id}>
            <td>{row.id}</td>
            <td>{row.totalDeposit.toLocaleString()}</td>
            <td>{row.totalCount.toLocaleString()}</td>
            <td>{row.v_totalDeposit.toLocaleString()}</td>
            <td>{row.v_totalCount.toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

 
const barData =  totalAmount?.platform ?? [];
const pieData = {
  labels: ['2016', '2017', '2018', '2019', '2020'], // Labels for each slice
  datasets: [
    {
      data: [80000, 45677, 78888, 90000, 4300], // The values for each slice (userGain or any other data)
      backgroundColor: [
        'rgba(75, 192, 192, 0.5)',
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 159, 64, 0.5)',
        'rgba(153, 102, 255, 0.5)'
      ], // Optional: Define the color for each slice
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(153, 102, 255, 1)'
      ],
      borderWidth: 1,
    },
  ],
};

  // redirect user on home if userid does not match
  useEffect(() => {
    if (User.data.Role_ID !== "64f710d9a2ab78980020c5fb") {
      navigate("/")
    }
  }, [User])
 
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="g-sidenav-show  bg-gray-100">
          <ToastContainer autoClose={2000} position="top-center" />
          <Sidenav />
          <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
            <Breadcrumbs tab={"Dashboard"} />
            <div
              className="container-fluid py-1"
              style={{ background: "#f8f9fa" }}
            >
              <div className="row">
                <div className="row tp-form">
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl">From Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="From Date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl">To Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="To Date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                        <FormControl
                          className="form-banner mt-2"
                          sx={{ m: 1, width: 200, marginLeft: 5 }}
                          size="small"
                        >
                          <InputLabel
                            className="label-banner"
                            id="demo-select-small-label"
                          >
                           Vendor Site
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedCategory}
                            label="Select"
                            fullWidth
                            onChange={handleCategoryChange}
                            error={categoryError}
                          >
                            <MenuItem value="all">all</MenuItem>
                            <MenuItem value="fb">fb</MenuItem>
                            <MenuItem value="laxminarayan">laxminarayan</MenuItem>
                            <MenuItem value="superbook247">superbook247</MenuItem>

                          </Select>
                        </FormControl>

                        {/* <FormGroup className="mt-2">
                          <FormControlLabel
                          control={
                            <Switch
                            checked={switchAddState}
                            onChange={(e) => handleAddSwitchChange(e)}
                            color="primary"
                            />
                          }
                          label={switchAddState ? "Active" : "Inactive"}
                          />
                        </FormGroup> */}
                      </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl"></label>
                    <a id="apply" className="sechBtn mt-1" onClick={handleAllData}>
                      Apply
                    </a>
                  </div>
                  <div className="col-6 col-xl-2 col-sm-4 pdrt">
                    <label className="lbl"></label>
                    <a id="alldata" className="sechBtn mt-1" onClick={handleAllData}>
                      All Data
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <StatCard title={"VendorGGR"} icon={faUserCircle}
                  value={`₹ ${Math.round(totalAmount?.total?.totalVendorGGR ?? 0)}`}
                  className={"fa fa-shopping-cart mid-icon"}
                />
                
                                {/* <StatCard title={"v_totalDeposit"} icon={faUserCircle} value={`₹ ${Math.round(totalAmount?.v_totalDeposit ?? 0)}`} className={"fa fa-shopping-cart mid-icon"} /> */}

                <StatCard title={"OurGGR"} icon={faUserCircle}
                  value={`${Math.round(totalAmount?.total?.totalOurGGR  ?? 0)}`}
                  className={"fa fa-shopping-cart mid-icon"}
                />
                {/* <StatCard title={"Total Registered Users Web Today"} icon={faUserCircle} value={regUserWebToday} className={"fa fa-globe mid-icon"} /> */}
                <StatCard title={"DiffGGR"} icon={faUserCircle} value={`${Math.round(totalAmount?.total?.totalDiffGGR ?? 0)}`} className={"fa fa-globe mid-icon"} />
                <StatCard title={"TotalDeposit"} icon={faUserCircle} value={`${Math.round(totalAmount?.total?.totalWithdraw ?? 0)}`} className={"fa fa-globe mid-icon"} />
                <StatCard title={"TotalWithdraw"} icon={faUserCircle} value={`${Math.round(totalAmount?.total?.totalDeposit ?? 0)}`} className={"fa fa-globe mid-icon"} /> 
                <StatCard title={"TotalDepositPending"} icon={faUserCircle} value={`${Math.round(totalAmount?.total?.totalDepositPending ?? 0)}`} className={"fa fa-globe mid-icon"} />
                <StatCard title={"TotalWithdrawPending"} icon={faUserCircle} value={`${Math.round(totalAmount?.total?.totalWithdrawPending ?? 0)}`} className={"fa fa-globe mid-icon"} /> 

                {/* <StatCard title={"Total Registered Users App Today"} icon={faUserCircle} value={regUserAppToday} className={"fa fa-globe mid-icon"} />
                <StatCard title={"Total Users Balance"} icon={faMoneyBillAlt} value={`₹ ${Math.round(balance)}`} className={"fa fa-user-circle-o mid-icon"} />
                <StatCard title={"Total Users Bonus Balance"} icon={faUserCircle} value={`₹ ${Math.round(bonusBalanceUser)}`} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Last 7 days Active Users"} icon={faUserCircle} value={Math.round(activeUser)} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Last 7 Days Active Users App"} icon={faUserCircle} value={Math.round(activeUserApp)} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Total Non Performing Users"} icon={faUserCircle} value={Math.round(nonPerformingUser)} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard title={"Liability"} icon={faShoppingCart} value={`₹ ${Math.round(liability)}`} className={"fa fa-shopping-cart mid-icon"} />
                <StatCard data={usersGlobalList} title={"Registered Users Fairbets Global"} icon={faUserCircle} value={`${Math.round(globalUser)}`} className={"fa fa-shopping-cart mid-icon"} /> */}

                {/* <Link style={{ textDecoration: "none" }} to={"/users"} state={{ selectActiveCustomers: true }} >
                  <StatCard title={"Today's Active Users"} icon={faShoppingCart} value={totalActiveCustomers} className={"fa fa-shopping-cart mid-icon"} />
                </Link> */}
              </div>
              <div className="row mt-4">
                <div className="col-12 mt-2">
                  <div className="table-responsive">
                      {/* <MergedTable totalAmount={totalAmount} /> */}
                  </div>
                  {/* <ul className="pagination d-flex justify-content-center">
                      <Stack spacing={2}>
                        <Pagination
                          count={totalPages}
                          color="secondary"
                          page={currentPage}
                          onChange={(event, newPage) => setCurrentPage(newPage)}
                        />
                      </Stack>
                    </ul> */}
                    
                </div>
              </div>
               <div className="row mt-4">
                <DashboardCardGraph title="GGR" ><BarChart_v barData={barData} /></DashboardCardGraph>
                <DashboardCardGraph title="GGR" ><PieChart_v  pieData={pieData} /></DashboardCardGraph>

                
                <DashboardCard title="Satta Matka Bets Today:" data={smBetsData} />
                <DashboardCard title="Qtech Bets Today:" data={casinoBetsData} />
                <DashboardCard title="Jetfair Bets Today:" data={exchangeBetsData} />
                <DashboardCard title="Evolution:" data={evolutionBetsData} />
              </div> 
              <footer className="footer mt-2  ">
                <div className="container-fluid">
                  <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-6 mb-lg-0 mb-4">
                      <div className="copyright text-center text-sm text-muted text-lg-start">
                        © fairbets.co
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <ul className="nav nav-footer justify-content-center justify-content-lg-end">
                        <li className="nav-item">
                          <a
                            href="#"
                            className="nav-link text-muted"
                            target="_blank"
                          />
                        </li>
                        <li className="nav-item">
                          <a
                            href="#/presentation"
                            className="nav-link text-muted"
                            target="_blank"
                          >
                            About Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#/blog"
                            className="nav-link text-muted"
                            target="_blank"
                          >
                            Blog
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="#/license"
                            className="nav-link pe-0 text-muted"
                            target="_blank"
                          >
                            License
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </main>
        </div>
      )}
    </>
  );
}

export default Home;