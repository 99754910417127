import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  PieChart, Pie, Sector, Cell,
  LabelList,
} from "recharts";


// BarChart component
export const BarChart_v = ({ barData }: any) => {
  // Check if barData exists and is an array
  if (!barData || !Array.isArray(barData) || barData.length === 0) {
    return <div>No data available</div>; // Show a message if data is invalid
  }
  // Prepare the chart data based on the provided data

  return (
    <div className="chart-container">
      <BarChart
        width={800}
        height={400}
        data={barData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="platform" />
        <YAxis />
        <Tooltip />
        <Legend />

        {/* Grouped bars for Our GGR and Vendor GGR */}
        <Bar dataKey="totalOurGGR" fill="#0088FE" name="Our GGR" >
          <LabelList dataKey="totalOurGGR" position="top" />

        </Bar>
        <Bar dataKey="totalVendorGGR" fill="#00C49F" name="Vendor GGR" >
          <LabelList dataKey="totalVendorGGR" position="top" />

        </Bar>

        {/* Negative difference displayed below the x-axis */}
        <Bar dataKey="totalDiffGGR" fill="#ff5f5f" name="Difference (negative)" >
          <LabelList dataKey="totalDiffGGR" position="top" />

        </Bar>
      </BarChart>
    </div>
  );
};
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
export const PieChart_v = ({ pieData }: any) => {
  const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ];
  return (
    <div className="chart-container">
      <h2 style={{ textAlign: "center" }}>Pie Chart</h2>
      <PieChart width={400} height={400}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
}


// DashboardCard component
const DashboardCard = ({ title, children }: any) => {
  // Prepare the chart data based on the provided data

  return (
    <div className="col-md-8 mb-8">
      <div className="card card-dashboard">
        <div className="card-body p-3">
          <h4 className="font-weight-bolder">{title}</h4>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
